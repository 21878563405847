import { ESortDir } from 'features/cases-management/types/TCasePagination'
import { notices } from 'features/notices'
import { SUMP_TAB } from 'pages/sump/ui/SumpTopActions'
import { useMutation, UseMutationOptions, useQuery, useQueryClient } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import IListOfItems from 'types/api/IListOfItems'
import { EUploadedFileSortBy } from 'types/IUploadedFile'
import { IUploadedFileDTO } from 'types/IUploadedFileDTO'

import sumpService, {
  AttachUploadedFilesDataType,
  DeleteUploadFilesDataType,
  UploadedFilesAvailableFilters,
} from './service'

export type SumpSlidesFilters = {
  createdDateFrom?: string
  createdDateTo?: string
  uploadedFileAttachmentStates?: string
  uploadedFileTypes?: string
  attachedToCaseDateFrom?: string
  attachedToCaseDateTo?: string
  deletedDateFrom?: string
  deletedDateTo?: string
  ascDirection?: boolean | null
  sortBy?: EUploadedFileSortBy
  sortDir?: ESortDir
  deletedByUserIds?: number[]
  attachedToCaseByUserIds?: number[]
  query?: string
}

export const useUploadedFilesQuery = (uploadedFileTab: string, filters: SumpSlidesFilters, query: string) => {
  const queryClient = useQueryClient()
  return useQuery<IListOfItems<IUploadedFileDTO> | undefined, unknown>(
    [
      QUERY_TYPE.UPLOADED_FILE,
      {
        uploadedFileTab,
        ...filters,
        query,
      },
    ],
    async (data: any) => {
      if (!data || !filters || query) return
      const result = await sumpService.fetchUploadedFiles({
        uploadedFileTab,
        ...filters,
        query,
        //@ts-ignore
        uploadedFileAttachmentStates: filters.uploadedFileAttachmentStates?.split(/[\s,]|[\s|]/).join(),
      })
      if (!result) return
      result.content.forEach((record) => {
        const cacheRecord = queryClient.getQueryData<IUploadedFileDTO>([
          QUERY_TYPE.UPLOADED_FILE,
          record.uploadedFileId,
        ])
        if (!cacheRecord) {
          queryClient.setQueryData<IUploadedFileDTO>([QUERY_TYPE.UPLOADED_FILE, record.uploadedFileId], () => record)
        }
      })
      return result
    },
  )
}

/**
 * Хук для загрузки файлов из всех статусов по поиску в отстойнике
 *
 * @param filters - фильтры страницы
 * @param query - поисковой запрос
 * @param searchMinLength - минимальная длина строки поиска
 */
export const useUploadedAllFilesQuery = (filters: SumpSlidesFilters, query: string, searchMinLength: number) =>
  useQuery<IListOfItems<IUploadedFileDTO> | undefined, unknown>(
    [
      QUERY_TYPE.UPLOADED_ALL_FILES,
      {
        ...filters,
        query,
      },
    ],
    async () =>
      query.length > searchMinLength - 1
        ? sumpService.fetchUploadedFiles({
            ...filters,
            query,
          })
        : undefined,
  )

/**
 * Хук для загрузки файлов-дубликатов по штрихкоду
 *
 * @param barcode - штрихкод
 */
export const useUploadedDuplicateFilesQuery = (barcode: string) =>
  useQuery<IListOfItems<IUploadedFileDTO> | undefined, unknown>(
    [QUERY_TYPE.UPLOADED_DUPLICATE_FILES, { query: barcode }],
    async () =>
      barcode
        ? sumpService.fetchUploadedDuplicateFiles({
            query: barcode,
            uploadedFileAttachmentStates: 'DUPLICATE',
            uploadedFileTab: 'NOT_ATTACHED_TO_CASE',
          })
        : undefined,
  )

export const useAvailableFiltersQuery = (uploadedFileTab?: SUMP_TAB) =>
  useQuery<UploadedFilesAvailableFilters | undefined, unknown>(
    [QUERY_TYPE.UPLOADED_FILES_FILTERS],
    async (data: any) => {
      if (!data) return
      return sumpService.fetchAvailableFilters({
        uploadedFileTab: uploadedFileTab || '',
      })
    },
  )

export const useAttachUploadedFiles = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (data: AttachUploadedFilesDataType) => {
      const notificationKey = 'attach-' + data.targetCaseId
      await sumpService.attachUploadedFiles(data)
      notices.openOnLinkingSlidesNotification({
        caseId: data.targetCaseId,
        caseName: data.caseName,
        count: data.uploadedFileIds.length,
        key: notificationKey,
      })
    },
    {
      onSuccess: (e, data) => {
        queryClient.invalidateQueries([QUERY_TYPE.UPLOADED_FILE])
        queryClient.invalidateQueries([QUERY_TYPE.UPLOADED_ALL_FILES])
        queryClient.invalidateQueries([QUERY_TYPE.CASE, data.targetCaseId])
        queryClient.invalidateQueries([QUERY_TYPE.UPLOADED_DUPLICATE_FILES])
        queryClient.invalidateQueries([QUERY_TYPE.UPLOADED_FILE_COUNT])
      },
    },
  )
}

export const useDeleteUploadFiles = (
  options?: UseMutationOptions<void, unknown, { uploadedFileIds: number[] }, unknown> | undefined,
) => {
  const queryClient = useQueryClient()
  return useMutation(async (data: DeleteUploadFilesDataType) => {
    const notificationKey = 'upload-slide-removed'
    await sumpService.deletedUploadedFiles(data)
    await queryClient.invalidateQueries([QUERY_TYPE.UPLOADED_FILE_COUNT])
    notices.openOnSumpSlideRemoveNotification({ key: notificationKey })
  }, options)
}

export const useMoveToTrash = (
  options?: UseMutationOptions<void, unknown, { uploadedFileIds: number[] }, unknown> | undefined,
) => {
  const queryClient = useQueryClient()
  return useMutation(async ({ uploadedFileIds }) => {
    await sumpService.moveToTrash({ uploadedFileIds })
    await queryClient.invalidateQueries([QUERY_TYPE.UPLOADED_FILE])
    await queryClient.invalidateQueries([QUERY_TYPE.UPLOADED_ALL_FILES])
    await queryClient.invalidateQueries([QUERY_TYPE.UPLOADED_DUPLICATE_FILES])
    await queryClient.invalidateQueries([QUERY_TYPE.UPLOADED_FILE_COUNT])
    await queryClient.invalidateQueries([QUERY_TYPE.SUMP_FILES_COUNT])
  }, options)
}

export const useRestoreBinTrash = (
  options?: UseMutationOptions<void, unknown, { uploadedFileIds: number[] }, unknown> | undefined,
) =>
  useMutation(async ({ uploadedFileIds }) => {
    await sumpService.restoreFromTrash({ uploadedFileIds })
  }, options)

export const useFilesCountQuery = () => {
  const queryClient = useQueryClient()
  return useQuery([QUERY_TYPE.SUMP_FILES_COUNT], async (data: any) => {
    if (!data) return
    const result = await sumpService.fetchFilesCount()
    queryClient.setQueryData(QUERY_TYPE.SUMP_FILES_COUNT, result)
    return result
  })
}
